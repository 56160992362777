/*
  Cloverleaf theme for React-Tippy tooltip component
 */

.tippy-box {
  box-shadow: 3px 4px 10px 0 rgba(0,0,0,0.10);
  border-radius: 5px;
  font-family: 'Roboto Condensed', sans-serif;
  padding: 4px 10px;
}

.tippy-box[data-theme~='cloverleaf'] {
  background-color: #FFE25F;
  border: 1px solid #FFE25F;
  color: #000;
}

.tippy-box[data-theme~='cloverleaf'][data-placement^='top'] > .tippy-arrow::before {
  border-top: 7px solid #FFE25F;
}

.tippy-box[data-theme~='cloverleaf'][data-placement^='right'] > .tippy-arrow::before {
  border-right: 7px solid #FFE25F;
}

.tippy-box[data-theme~='cloverleaf'][data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom: 7px solid #FFE25F;
}

.tippy-box[data-theme~='cloverleaf'][data-placement^='left'] > .tippy-arrow::before {
  border-left: 7px solid #FFE25F;
}
